/**
 * Product Composable
 */

import dayjs from 'dayjs';
import type { GalleryProps } from '~/components/mol/Gallery.vue';
import type { CurrencyCode, Product, ProductLabel, ProductPreview, ProductPreviewContent, ProductVariant } from '~/graphql/generated';
import type { LocationQueryValue } from '#vue-router';
import type { BikeDetailInfo } from '~/@types/product';

export const useProduct = () => {
    const { t, d, n, isoLocale, iso2Country, iso2Lang, metric, imperial, localeSuffix } = useLocales();

    const resolveImage = (variant: ProductVariant): string | undefined => {
        const galleryData = variant.content?.gallery?.[0]?.data;
        if (!galleryData) return;
        const selectedSize = galleryData['1to1'] || galleryData['image'];
        return (galleryData.baseUrl ?? '') + selectedSize;
    };

    const processCollection = (products: Array<Partial<ProductPreview>>, bundleUsage: boolean = false): Array<CMSProduct> => {
        const ret: CMSProduct[] = [];
        for (const p of products) {
            // console.log('process raw ProductPreview', p);
            const item = processProductPreview(p);
            if (item) {
                ret.push(item);
            }
        }
        return bundleUsage ? ret : ret.filter((p: CMSProduct) => p.url && p.url !== '');
    };

    const isBike = (product: { types?: string[] | null } | undefined | null = {}) => Boolean(product?.types?.includes('Bike'));

    const createBikeDetail = (product: Partial<Product> | Partial<ProductPreviewContent> | undefined | null): BikeDetailInfo | undefined => {
        let bikeDetail: any;
        if (product && isBike(product)) {
            /**
             * we need to format the values accordingly
             * if (lang.value === 'de' || lang.value === 'it' || lang.value === 'fr') {
                        // console.log('da', value, value.replace(/\./g, ','))
                        return value.replace(/\./g, ',');
                    }
             */
            bikeDetail = {};
            bikeDetail.wheels = {
                label: t('bikeDetail.bike'),
                value: `${product.wheels?.toString()}″`,
            };
            bikeDetail.weight = {
                label: t('bikeDetail.weight'),
            };
            const metricValue = product.weight?.metric ? `${product.weight.metric}` : '';
            const imperialValue = product.weight?.imperial ? `${product.weight.imperial}` : '';
            if (metric && imperial) {
                bikeDetail.weight.value = `${metricValue} kg (${imperialValue} lbs)`;
            } else if (metric) {
                bikeDetail.weight.value = `${metricValue} kg`;
            } else if (imperial) {
                bikeDetail.weight.value = `${imperialValue} lbs`;
            }

            bikeDetail.features = {
                label: t('bikeDetail.features'),
                value: product.features,
            };

            bikeDetail.age = {
                label: t('bikeDetail.age'),
                value: `${product.min_age} - ${product.max_age} ${t('bikeDetail.years')}`,
            };

            bikeDetail.size = {
                label: t('bikeDetail.height'),
            };
            const metricSize = `${product.min_height?.metric} - ${product.max_height?.metric} cm`;
            const imperialSize = `${product.min_height?.imperial} - ${product.max_height?.imperial}″`;
            if (metric && imperial) {
                bikeDetail.size.value = `${metricSize} (${imperialSize})`;
            } else if (metric) {
                bikeDetail.size.value = metricSize;
            } else if (imperial) {
                bikeDetail.size.value = imperialSize;
            }
        }
        return bikeDetail;
    };

    const prepCarouselImages = (p: CMSProduct, image?: string, hover?: string) => {
        const keys = Object.keys(p.carouselImages);
        if (keys.length === 0 && p.options?.Color) {
            for (const color of p.options.Color) {
                const v = p.variants.items.find((v: CMSProductVariant) => v.selectedOptions?.Color.includes(color));

                if (v && v.image) {
                    if (!p.image) {
                        p.image = v.image;
                    }

                    p.carouselImages[color] = {
                        id: v.id,
                        active: false,
                        image: v.image,
                        sku: v.sku,
                        state: v.availabilityState,
                        price: v.price || '',
                    };
                }
            }
        }
        if (!p.image) {
            p.image = p.variants.items[0].image || image;
        }
        if (!p.hover) {
            p.hover = hover;
        }
        p.defaultVariant = {
            id: p.variants.items[0].id,
            sku: p.variants.items[0].sku,
            state: p.variants.items[0].availabilityState,
            price: p.variants.items[0].price || '',
        };
    };

    const availabilityInfo = (v: CMSProductVariant) => {
        if (v) {
            switch (v.availabilityState) {
                case ProductAvailability.OUT_OF_STOCK: {
                    if (v.canBeNotified) {
                        return t('availability.notifyMe');
                    }
                    return t('availability.outOfStock');
                }
                case ProductAvailability.NOT_IN_THIS_REGION: {
                    return t('availability.notInThisRegion');
                }
                case ProductAvailability.COMING_SOON: {
                    const { availabilityDate } = v;
                    let displayDate = '';
                    if (availabilityDate) {
                        const d = dayjs(availabilityDate);
                        const now = dayjs();
                        const diff = d.diff(now, 'week');
                        if (diff === 0) {
                            displayDate = t('availability.weeks', { period: '1-2' });
                        } else {
                            displayDate = t('availability.weeks', { period: `${diff}-${diff + 1}` });
                        }
                    }
                    // TODO: localize date before render
                    // We still need to pick a date handling lib.
                    if (displayDate === '') {
                        return t('availability.comingsoon.short');
                    } else {
                        return t('availability.comingsoon.long', {
                            date: displayDate,
                        });
                    }
                }

                case ProductAvailability.PREORDER: {
                    const { shippingDate } = v;
                    // console.log('shipping date is', shippingDate)
                    let displayDate = '';
                    if (shippingDate) {
                        const d = dayjs(shippingDate);
                        const now = dayjs();
                        const diff = d.diff(now, 'day');
                        // console.log('diff between ', d.date(), now.date(), diff)
                        // console.log('diff is', diff)
                        if (diff > 0 && d.date() !== now.date()) {
                            displayDate = d.add(7, 'days').format('MMM. D, YYYY');
                        }
                    }
                    // TODO: localize date before render
                    // We still need to pick a date handling lib.
                    if (displayDate === '') {
                        return t('availability.preorder.short');
                    } else {
                        return t('availability.preorder.long', {
                            date: displayDate,
                        });
                    }
                }

                case ProductAvailability.AVAILABLE:
                    return undefined;
                default:
                    return undefined;
            }
        }
        return t('availability.outOfStock');
    };

    const parseVariants = (
        title: string,
        formattedTitle: string,
        types: string[],
        defaultImage: string | undefined,
        variants: ProductVariant[] | undefined,
    ) => {
        const appStore = useAppStore();
        const orderCodes = appStore.orderCodes;
        // order codes to check if we can enable an existing variant

        const normalizedVariants: any[] = [];
        const productOptions: any = {};
        const optionsSequence: string[] = [];
        const notifyMeForm = appStore.website?.shop?.notify_me || appStore.website?.shop?.notify_me2;
        let currencyCode;
        let minPrice = -1;
        if (Array.isArray(variants)) {
            for (const v of variants) {
                if (v && !v?.hidden) {
                    // skip the following props: weight, weightUnit, currentlyNotInStock, unitPrice
                    let state: any = v.availabilityState;
                    if (state === ProductAvailability.LOCKED) {
                        if (orderCodes && orderCodes.includes(v.orderCode)) {
                            state = v.preOrder && v.shippingDate ? ProductAvailability.PREORDER : ProductAvailability.AVAILABLE;
                        } else {
                            state = ProductAvailability.OUT_OF_STOCK;
                        }
                    }
                    const amount = v.price?.amount ? Number.parseFloat(v.price.amount) : 0;
                    const compareAt = v.compareAtPrice?.amount ? Number.parseFloat(v.compareAtPrice.amount) : 0;
                    // notify me check
                    let canBeNotified = false;
                    if (state === ProductAvailability.OUT_OF_STOCK) {
                        // only show notify me state if notifyMe flag ist set and the form is present
                        if (v.notifyMe && notifyMeForm) {
                            canBeNotified = true;
                            state = ProductAvailability.NOTIFY_ME;
                        } else if (v.notifyMe && iso2Country.value?.toLowerCase() === 'us') {
                            canBeNotified = true;
                            state = ProductAvailability.NOTIFY_ME;
                        }
                    }
                    // normalize the selected product options
                    const selectedOptions: any = {};
                    for (const so of v?.selectedOptions ?? []) {
                        if (!so?.name) continue;
                        if (so.name === 'Title' && so.value === 'Default Title') {
                            continue;
                        }
                        selectedOptions[so.name] = so.value;
                    }
                    let variantId;
                    const idx = v.id.lastIndexOf(':');
                    const hash = v.id && idx > 0 ? v.id.substring(idx + 1) : null;
                    if (hash) {
                        if (import.meta.client) {
                            variantId = atob(hash).substring('gid://shopify/ProductVariant/'.length);
                        } else {
                            const bu = Buffer.from(hash, 'base64');
                            variantId = bu.toString('utf8').substring('gid://shopify/ProductVariant/'.length);
                        }
                    }
                    const shopTitle = v.shopTitle !== 'Default Title' ? v.shopTitle : '';
                    const vTitle = shopTitle !== '' ? `${title} - ${shopTitle}` : title;
                    const fTitle = shopTitle !== '' ? `${formattedTitle} - ${shopTitle}` : formattedTitle;
                    const shopImage = v.shopImage !== '' ? v.shopImage : undefined;
                    const image = isBike({ types }) && v.sku !== '' ? getAssetUrl(`${v.sku}-side`) : resolveImage(v) || defaultImage;
                    const priceNumber = v.price?.amount ? Number.parseFloat(v.price.amount) * 100 : 0;
                    // define the minimum price to be displayed on the product
                    if (priceNumber > 0 && (priceNumber < minPrice || minPrice === -1)) {
                        minPrice = priceNumber;
                        currencyCode = v.price?.currencyCode;
                    }
                    const normalizedVariant: CMSProductVariant = {
                        // ...v,
                        id: v.id,
                        nativeId: v.nativeId as string,
                        variantId,
                        sku: v.sku as string,
                        barcode: v.barcode as string,
                        availabilityState: state,
                        image,
                        shopImage: shopImage as string,
                        requiresShipping: Boolean(v.requiresShipping),
                        variantTitle: shopTitle as string,
                        title: vTitle,
                        formattedTitle: fTitle,
                        priceNumeric: priceNumber,
                        currency: currencyCode as CurrencyCode,
                        price:
                            v.price?.amount && v.price?.amount !== ''
                                ? new Intl.NumberFormat(isoLocale.value, { style: 'currency', currency: v.price.currencyCode }).format(
                                      Number(v.price.amount),
                                  )
                                : undefined,
                        compareAtPrice:
                            v.compareAtPrice?.amount && v.compareAtPrice?.amount !== '' && amount < compareAt
                                ? new Intl.NumberFormat(isoLocale.value, { style: 'currency', currency: v.compareAtPrice.currencyCode }).format(
                                      Number(v.compareAtPrice.amount),
                                  )
                                : undefined,
                        canBeNotified,
                        canBeOrdered: state === ProductAvailability.AVAILABLE || state === ProductAvailability.PREORDER,
                        redCharity: v.redCharity,
                        shippingDate: v.shippingDate ? new Date(v.shippingDate) : undefined,
                        availabilityDate: v.availabilityDate ? new Date(v.availabilityDate) : undefined,
                        selectedOptions,
                        // TODO: check if we can find a better way than a type cast
                        labels: v.labels as ProductLabel[],
                        quantityAvailable: v.quantityAvailable ?? 0,
                    };
                    normalizedVariant.availabilityInfo = availabilityInfo(normalizedVariant);
                    if (state === ProductAvailability.PREORDER) {
                        if (normalizedVariant.shippingDate) {
                            const d = dayjs(normalizedVariant.shippingDate);
                            const now = dayjs();
                            const diff = d.diff(now, 'day');
                            if (diff > 0) {
                                normalizedVariant.preorderMessage = t('availability.preorder.line', {
                                    date: d.add(7, 'days').format('MMM. D, YYYY'),
                                });
                            }
                        }
                    }
                    normalizedVariants.push(normalizedVariant);
                    for (const o of v?.selectedOptions ?? []) {
                        if (!o?.name) continue;
                        if (!optionsSequence.includes(o.name)) {
                            optionsSequence.push(o.name);
                        }
                        if (!productOptions[o.name]) {
                            productOptions[o.name] = [o.value];
                        } else if (!productOptions[o.name].includes(o.value)) {
                            productOptions[o.name].push(o.value);
                        }
                    }
                }
            }
        }
        // remove the default title option on single variants
        if (productOptions.Title && productOptions.Title.length === 1 && productOptions.Title[0] === 'Default Title') {
            delete productOptions.Title;
            if (optionsSequence.includes('Title')) {
                optionsSequence.splice(optionsSequence.indexOf('Title'), 1);
            }
        }
        if (minPrice > 0) {
            minPrice = Math.round(minPrice) / 100;
        }

        return {
            variants: normalizedVariants,
            productOptions,
            optionsSequence,
            minPrice,
            currencyCode,
        };
    };

    const parseTags = (product: Partial<Product> | Partial<ProductPreview>): string[] => {
        const tags: string[] = [];
        if (product.tags) {
            for (const tag of product.tags) {
                if (tag) {
                    tags.push(tag);
                }
            }
        }
        return tags;
    };

    const getNativeProductId = (id: string) => {
        if (!process.server) {
            return atob(id).substring('gid://shopify/Product/'.length);
        } else {
            const bu = Buffer.from(id, 'base64');
            return bu.toString('utf8').substring('gid://shopify/Product/'.length);
        }
    };

    const processProductData = (prod: Partial<Product | ProductPreview>) => {
        // if at least one variant has preOrder,
        let hasPreOrder, hasCustomNaming, hasNotifyMe, overviewImage, hoverImage;
        if (prod.variants?.items) {
            for (const variant of prod.variants?.items) {
                if (variant?.customNaming) {
                    hasCustomNaming = true;
                }
                if (variant?.preOrder) {
                    hasPreOrder = true;
                }
                if (variant?.notifyMe) {
                    hasNotifyMe = true;
                }
            }
        }
        if ('content' in prod && prod.content?.overview) {
            overviewImage = prod.content.overview.data?.src;
        } else if ('overview' in prod) {
            overviewImage = prod.overview?.data?.src;
        }
        if ('content' in prod && prod.content?.hover) {
            hoverImage = prod.content.hover.data?.src;
        } else if ('hover' in prod) {
            // WHY???
            // TODO: hover does not exist on any of those two types
            // hoverImage = prod.hover?.data?.src;
            hoverImage = (prod.hover as any)?.data?.src;
        }
        return {
            preOrder: !!hasPreOrder || prod.preOrder,
            notifyMe: !!hasNotifyMe || prod.notifyMe,
            customNaming: !!hasCustomNaming || prod.customNaming,
            overviewImage,
            hoverImage,
        };
    };

    const getDefaultImage = (image: any): string | undefined => {
        if (image) {
            if (typeof image === 'string') {
                return image;
            } else if (typeof image === 'object') {
                if (image.id?.indexOf('a:') === 0) {
                    return image.data.src || undefined;
                } else if (image.id?.indexOf('b:') === 0) {
                    return `${image.data.baseUrl}${image.data['1to1']}`;
                }
            }
            // console.log('get default image and content', prod.content);
        }
        // return prod.shopImage || undefined;
        return undefined;
    };

    /**
     * determines if we have special flags or tags that prevent a quick sale and require the pdp (or a modal)
     * @param prod CMSProduct
     */
    const canProductQuickSell = (prod: CMSProduct) => {
        if (prod.customNaming) return false;
        // custom drop down for custom attribute
        if (prod.tags?.includes('pdp-original-selection')) return false;
        if (prod.preOrder || prod.notifyMe) return false;
        return true;
    };

    const canVariantQuickSell = (prod?: CMSProduct, variantId?: string) => {
        if (!variantId) return false;
        if (!prod) return false;
        if (prod.customNaming) return false;
        // custom drop down for custom attribute
        if (prod.tags?.includes('pdp-original-selection')) return false;
        const variant = prod.variants.items.find((v: CMSProductVariant) => v.id === variantId);
        if (variant) {
            return variant.availabilityState === ProductAvailability.AVAILABLE;
        }
        return false;
    };

    const processProductPreview = (product: Partial<ProductPreview>): CMSProduct | undefined => {
        if (!product) {
            return undefined;
        }
        // if it is a bike, generate the lines
        const bikeDetail = createBikeDetail(product.content);
        // console.log('product types', product);
        const title = product.shopTitle || 'Undefined Title';
        const formattedTitle = product.content?.title || title;
        const types = product.content?.types || ['Undefined'];
        const defaultImage = getDefaultImage(product.content?.overview);

        const productId = product.nativeId ? getNativeProductId(product.nativeId) : undefined;

        // filter variants that are hidden
        const { variants, productOptions, minPrice, currencyCode, optionsSequence } = parseVariants(
            title,
            formattedTitle,
            types,
            defaultImage,
            product.variants?.items as any[],
        );
        // console.log('min price is', title, minPrice, currencyCode);
        const price =
            minPrice > 0 ? new Intl.NumberFormat(isoLocale.value, { style: 'currency', currency: currencyCode }).format(minPrice) : undefined;
        // build up product options
        const tags = parseTags(product);
        const { preOrder, customNaming, notifyMe, overviewImage, hoverImage } = processProductData(product);
        const ret: CMSProduct = {
            // ...product,
            id: product.id as string,
            nativeId: product.nativeId as string,
            productId,
            handle: product.handle as string,
            customNaming: customNaming || false,
            forSale: true,
            preOrder: preOrder || false,
            notifyMe: notifyMe || false,
            isGiftCard: false, // if needed we need to add the flag on the api
            title,
            price,
            priceNumeric: minPrice,
            currency: currencyCode as CurrencyCode,
            formattedTitle,
            bikeDetail,
            productType: product.productType || 'Undefined',
            labels: product.labels as ProductLabel[],
            limit: product.limit as number,
            tags,
            types,
            category: product.content?.category || [],
            options: productOptions,
            optionsSequence,
            variants: {
                total: variants.length,
                items: variants, // normalizedVariants,
            },
            url: product.content?.url.url,
            hasContentPage: !!product.content?.url.url,
            carouselImages: {},
            kenticoImage: overviewImage,
            kenticoHover: hoverImage,
        };
        if (product.content?.hover) {
            // console.log('content is', product.content.hover)
        }
        prepCarouselImages(ret, getDefaultImage(product.content?.overview), getDefaultImage(product.content?.hover));
        return ret;
    };

    const processProduct = (product?: Partial<Product>): CMSProduct | undefined => {
        if (!product) {
            return undefined;
        }
        // if it is a bike, generate the lines
        const bikeDetail = createBikeDetail(product);
        const title = product.shopTitle || 'Undefined Title';
        const formattedTitle = product.title || title;
        const types = product.types || ['Undefined'];
        const image = getDefaultImage(product);

        const productId = product.nativeId ? getNativeProductId(product.nativeId) : undefined;

        // filter variants that are hidden
        const { variants, productOptions, optionsSequence, minPrice, currencyCode } = parseVariants(
            title,
            formattedTitle,
            types,
            image,
            product.variants?.items as any[],
        );
        // build up product options
        // console.log('min price is', title, minPrice, currencyCode);
        const price =
            minPrice > 0 ? new Intl.NumberFormat(isoLocale.value, { style: 'currency', currency: currencyCode }).format(minPrice) : undefined;
        // build up product options
        const tags = parseTags(product);
        const { preOrder, customNaming, notifyMe, overviewImage, hoverImage } = processProductData(product);

        const ret: CMSProduct = {
            // ...product,
            id: product.id as string,
            nativeId: product.nativeId as string,
            productId,
            handle: product.handle as string,
            title,
            price,
            priceNumeric: minPrice,
            currency: currencyCode as CurrencyCode,
            formattedTitle,
            bikeDetail,
            productType: product.productType || 'Undefined',
            labels: product.labels as ProductLabel[],
            limit: product.limit as number,
            tags,
            types,
            customNaming: customNaming || false,
            preOrder: preOrder || false,
            notifyMe: notifyMe || false,
            isGiftCard: false,
            forSale: true,
            category: product.category || [],
            options: productOptions,
            optionsSequence,
            hasContentPage: true,
            variants: {
                total: variants.length,
                items: variants, // normalizedVariants,
            },
            carouselImages: {},
            kenticoImage: overviewImage,
            kenticoHover: hoverImage,
        };
        prepCarouselImages(ret);
        return ret;
    };

    const getConfiguratorId = ({ handle }: { handle: string }) =>
        `${localeSuffix}:${iso2Lang.value}:collection:${handle}-configurator`.toLocaleLowerCase();

    const slideAvailability = (p: CMSProduct): boolean[] => {
        // loop through the colors and set the availability as true/false
        if (p.carouselImages) {
            const keys = Object.keys(p.carouselImages);
            const ret: boolean[] = [];
            for (const key of keys) {
                ret.push(p.carouselImages[key].state === ProductAvailability.AVAILABLE);
            }

            return ret;
        }
        return [];
    };

    return {
        isBike,
        processCollection,
        processProduct,
        processProductPreview,
        canVariantQuickSell,
        getConfiguratorId,
        slideAvailability,
    };
};

export const useSwiperCardImage = (product: CMSProduct | undefined, hoverColor: Ref<string>, chosenColor: Ref<string | undefined>) => {
    return computed(() => {
        if (!product) return;
        if (!chosenColor.value) return product.image;
        const carouselImages = product.carouselImages;
        return carouselImages?.[hoverColor.value]?.image || carouselImages[chosenColor.value]?.image || product.image;
    });
};

export const useUspGallery = (productId: Ref<string | undefined | null>, usp: undefined | WidgetList<ProductUsp>) => {
    return computed(() => {
        if (!productId.value) return [];
        if (!usp) return [];

        return usp.sequence.reduce((accumulator: GalleryProps['data'], id) => {
            const comp = usp.components.find((p) => p.id === id);
            if (comp) {
                accumulator.push({
                    uspTitle: comp.title ?? '',
                    // uspSubtitle: comp.subtitle, // currently not used in gallery
                    uspText: comp.content ?? '',
                    src: getAssetUrl(`${productId.value}-${comp.code}`),
                    productBadge: comp.product_badge,
                    alt: comp.image_alt,
                    video: comp.video,
                });
            }
            return accumulator;
        }, []);
    });
};

export const useColorOption = (config: IConfigurable | undefined) => {
    const colorOption = computed(() => {
        return config?.options?.find((option) => option.type === 'Color');
    });
    const selectedColor = computed(() => colorOption.value?.selectedRef.value);
    const hoverColor = ref<string>('');

    const colorOptionChanged = (option: string) => {
        colorOption.value?.setSelected(option);
    };
    const changeHoverColorOption = (option: string) => {
        hoverColor.value = option;
    };
    return {
        colorOption,
        selectedColor,
        hoverColor,
        colorOptionChanged,
        changeHoverColorOption,
    };
};

export const useProductCard = (
    product?: CMSProduct,
    initOptions?: {
        preselectSku?: LocationQueryValue | LocationQueryValue[];
        variantUrl?: LocationQueryValue | LocationQueryValue[];
    },
) => {
    const nuxtApp = useNuxtApp();
    const { ConfigurableProduct } = useEcommerce();
    const { addToCart, cartBusy } = useCart();

    const addToCartClicked = ref(false);

    const config = product?.variants?.items ? new ConfigurableProduct(product, initOptions) : undefined;

    const variant = computed(() => {
        if (!config) return undefined;
        return config.selected.value;
    });

    const { colorOption, selectedColor, hoverColor, colorOptionChanged, changeHoverColorOption } = useColorOption(config);
    const currentImage = useSwiperCardImage(product, hoverColor, selectedColor);

    const toURL = computed(() => {
        return nuxtApp.$helpers.generateLocalePath(config?.variantUrl?.value);
    });

    // TODO: Investigate why validate.limit reached does not update in handleAddToCart
    const handleAddToCart = (componentOrigin?: string) => {
        if (!config || !product || !variant.value) return;

        addToCartClicked.value = true;
        addToCart(
            [
                {
                    product,
                    variant: variant.value,
                    qty: 1,
                    attributes: {
                        _isBike: `${config?.isBike}`,
                    },
                },
            ],
            undefined,
            componentOrigin,
        ).finally(() => {
            addToCartClicked.value = false;
        });
    };

    const addToCartDisabled = computed(() => {
        if (!config || !product || !variant.value) return;
        return (cartBusy.value && !addToCartClicked) || variant.value.availabilityState === ProductAvailability.OUT_OF_STOCK || !config.valid.value;
    });

    const isRedCharity = computed(() => {
        if (!variant.value) return false;
        const hoverColorVariant = hoverColor.value
            ? config?.raw.variants.items?.find((variant) => variant.selectedOptions.Color === hoverColor.value)
            : undefined;
        return (variant.value.redCharity && !hoverColorVariant) || hoverColorVariant?.redCharity;
    });

    return {
        config,
        variant,
        colorOption,
        selectedColor,
        hoverColor,
        colorOptionChanged,
        changeHoverColorOption,
        currentImage,
        handleAddToCart,
        cartBusy,
        addToCartClicked,
        toURL,
        addToCartDisabled,
        isRedCharity,
    };
};

export const useProductMicrodata = (productVariant?: CMSProductVariant | undefined, description?: string | null | undefined) => {
    if (!productVariant) return;

    const getAvailabilityStatus = () => {
        if (productVariant.quantityAvailable < 1) {
            return 'OutOfStock';
        } else if (productVariant.quantityAvailable <= ProductQuantityAvailable.LOW_STOCK) {
            return 'LimitedAvailability';
        } else if (productVariant.availabilityState === ProductAvailability.PREORDER) {
            return 'PreOrder';
        }
        return 'InStock';
    };
    const pdpSnippet: {
        '@context': string;
        '@type': string;
        name: string;
        image: string | undefined;
        sku: string;
        description?: string;
        brand?: {
            '@type': string;
            name: string;
        };
        offers: {
            '@type': string;
            price: string | undefined;
            priceCurrency: string;
            availability: string;
        };
    } = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: productVariant.title,
        image: productVariant.shopImage,
        sku: productVariant.sku,
        brand: { '@type': 'Brand', name: 'woom' },
        offers: {
            '@type': 'Offer',
            price: productVariant.price,
            priceCurrency: productVariant.currency,
            availability: getAvailabilityStatus(),
        },
    };

    if (description) {
        pdpSnippet.description = description;
    }

    useHead({
        script: [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(pdpSnippet),
                key: 'pdp-microdata',
            },
        ],
    });
};
